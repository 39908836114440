import React, { Component } from 'react';
import './App.css';
//Impprt Router
import {
    BrowserRouter as Router,
    Route,
    link
} from 'react-router-dom';

// Import pages
import Home from './components/home/home.js'

import Header from './components/header/header';

function App() {
  return (
    <Router>
        <div className="App">
            <Header />
            <Route exact path='/' component={Home}/>
        </div>
    </Router>
  );
}

export default App;

import React, { Component } from 'react';
//Import navigation specific css
import './navigation.css'

//Import navigation items
import NavItem from './navItem'
import NavLogo from './navLogo'

//Impprt Router
import {
    BrowserRouter as Router,
    Route,
    Link
} from 'react-router-dom';

class Navigation extends Component {
    navSlide = () => {
        const burger = document.querySelector(".burger");
        const nav = document.querySelector(".nav-links")
        const navLinks = document.querySelectorAll(".nav-links li")

        burger.addEventListener("click", () => {
            nav.classList.toggle('nav-inactive')
            nav.classList.toggle('nav-active')
        })

        navLinks.forEach((link, index) => {
            link.style.animation = `navLinkFade 0.5s ease forward ${index / 7}s`
        })
    }

    closeBurger = () => {
        const links = document.querySelectorAll(".nav-links a")
        const nav = document.querySelector(".nav-links")

        links.forEach(link => {
            link.addEventListener('click', () => {
                nav.classList.toggle("nav-active")
                nav.classList.toggle('nav-inactive')
            })
        })
    }



    render() {
        return (
            // <div className="navbar navbar-default">
            //     <div className="row justify-content-md-center">
            //         <div className="navbar mr-auto navbar-light justify-content-md-center">
            //             <div className="container">
            //                 <ul className="nav ml-auto">
            //                     <li className="nav-item">
            //                         <Link to={"/"} className='nav-link mod-nav-link'>Electric Tin Openers</Link>
            //                     </li>
            //                     <li className='nav-item'>
            //                         <Link to={"/listen"} className='disabled nav-link mod-nav-link'>Listen</Link>
            //                     </li>
            //                     <li>
            //                         <Link to={"/watch"} className='disabled nav-link mod-nav-link'>Watch</Link>
            //                     </li>
            //                     <li>
            //                         <Link to={"/about"} className='disabled nav-link mod-nav-link'>About</Link>
            //                     </li>
            //                 </ul>
            //             </div>
            //         </div>
            //     </div>
            //</div>

            //Custom Navigation
            <div className='nav'>
                <Link to={"/"} className='logo'>Electric Tin Openers</Link>
                <ul className="nav-links nav-inactive">
                    <li>
                        <Link to={"/listen"} className=''>Listen</Link>
                    </li>
                    <li>
                        <Link to={"/watch"} className=''>Watch</Link>
                    </li>
                    <li>
                        <Link to={"/about"} className=''>About</Link>
                    </li>
                </ul>
                <div className="burger">
                    <div className="line1"></div>
                    <div className="line2"></div>
                    <div className="line3"></div>
                </div>
            </div>
        );
    }

    componentDidMount() {
        this.navSlide()
        this.closeBurger()
    }
}

export default Navigation;

import React, { Component } from 'react';

import tele from './tele.jpg'
import './home.css'
import {Link} from "react-router-dom";

class Home extends Component {
    render(){
        return (
            <div className="home-container">
                <div className="hero">
                    <div className="welcome">
                        <h1 className="primary-text"> Electric Tin Openers</h1>
                        <h2 className="">The New Album Releases - March 1st 2021</h2>
                        <div className="btn-container">
                            <button className="btn">
                                <Link to={"/listen"} className=''>Listen</Link>
                            </button>
                            <button className="btn">
                                <Link to={"/watch"} className=''>Watch</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Home;